<template>
    <div>

        <b-card no-body>
            <b-row style="min-height: 60vh;" class="p-2">
                <b-col cols="12" class="d-flex flex-column" style="gap: 1rem;">
                    <div class="border-right bg-light rounded-lg p-1" style="height: 40rem; overflow-y: auto;">
                        <div class="bg-white rounded-lg" v-for="cart in cartItems" :key="cart.sc_id">
                            <div class="d-flex border rounded-lg mb-1" style="height: 150px; padding: 5px; gap: 10px">
                                <img style="height: 100%; width: 100%; max-width: 150px; object-fit: cover;"
                                    class="rounded-lg" :src="FILESURL + cart.images[0].spi_image" />
                                <div class="d-flex flex-column justify-content-start w-100" style="padding: 10px;">
                                    <h2>{{ cart.sp_name }}</h2>
                                    <div class="border w-25 mb-1"></div>

                                    <div style="overflow-y: auto; width: fit-content;" class="pr-1">
                                        <div class="d-flex align-items-center" style="gap: 5px; margin-bottom: 8px;"
                                            v-for="item in cart.cart" :key="'item_' + item.sc_id">
                                            <p style="color: #999">{{ item.spqa_quantity }} peices for Rs.{{
                                                item.spqa_amount }}
                                            </p>
                                            <b-button variant="danger" pill @click="() => removeCartItems([item.sc_id])"
                                                style="padding: 0px; width: 20px; height:20px;"
                                                class="d-flex align-items-center justify-content-center">
                                                <feather-icon icon="TrashIcon" size="10" stroke="white"
                                                    class="m-0 p-0" />
                                            </b-button>
                                        </div>
                                    </div>

                                    <!-- <div v-html="cart.sp_description" class="pr-5"></div> -->

                                    <!-- <p>{{ cart.total_quantity }}</p> -->
                                </div>
                                <div class="d-flex align-items-center">
                                    <h4 class="text-nowrap">Rs.{{ cart.total_price }} ({{ cart.total_quantity }} pieces)
                                    </h4>
                                </div>
                                <div class="border my-3"></div>
                                <div class="d-flex align-items-center pr-1">
                                    <b-button variant="danger" pill style="padding: 0px; width: 30px; height:30px;"
                                        @click="() => removeCartItems(cart.cart.map(c => c.sc_id))">
                                        <feather-icon icon="TrashIcon" size="13" stroke="white" />
                                    </b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                        <h4 class="m-0 p-0">Cart Total: Rs. {{ cartTotal }}</h4>
                        <b-button variant="primary" class="ml-2" size="sm" :to="{ name: 'Shop Checkout' }"
                            :disabled="cartItems.length <= 0">Proceed
                            to
                            Checkout</b-button>
                    </div>

                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BButton,
    BFormInput,
    BTable,
    BBadge,
    BPagination,
    BRow,
    BCol,
    BImg,
    BMedia,
    BCarousel,
    BCarouselSlide,
    BTooltip,
} from "bootstrap-vue";

import CommonServices from "@/apiServices/CommonServices";
import { FILESURL } from "@/config";
import VueSelect from "vue-select";
import AgentServices from "@/apiServices/AgentServices";
export default {
    components: {
        BCard,
        BButton,
        BFormInput,
        BTable,
        BBadge,
        BPagination,
        BRow,
        BCol,
        BImg,
        BMedia,
        BCarousel,
        BCarouselSlide,
        BTooltip,
        VueSelect,
    },
    data() {
        return {
            cartItems: [],
            cartTotal: 0,
            FILESURL,
            filters: {
                sc_quantity: null
            },
            product: null,
            products: []
        }
    },
    directives: {
        BTooltip
    },
    methods: {
        async getAllProducts() {
            try {
                const res = await AgentServices.getAllProducts(this.filters);
                if (res.data.status) {
                    this.products = res.data.data;
                } else {
                    console.error('Could Not Get All Products');
                }
            } catch (error) {
                console.error(error, 'Could Not Get All Products');
            }
        },
        async getCartItems() {


            const res = await CommonServices.getCartItems()

            if (res.data.status) {
                let cartItems = res.data.data.data
                const uniqueCartItems = Array.from(new Set(cartItems.map(item => item.sc_sp_id)));
                let proObj = {}

                for (let id of uniqueCartItems) {
                    proObj[id] = cartItems.filter((elem) => elem.sc_sp_id === id)
                }

                let finalCartItems = []
                for (let key in proObj) {
                    let currentProductCartItems = proObj[key]
                    let product = this.products.find(p => p.sp_id === parseInt(key));

                    let finalCartItem = {
                        ...product,
                        total_quantity: currentProductCartItems.reduce((acc, c) => acc + c.spqa_quantity, 0),
                        total_price: currentProductCartItems.reduce((acc, c) => acc + c.spqa_amount, 0),
                        cart: currentProductCartItems,
                    }

                    finalCartItems.push(finalCartItem)
                }


                this.cartTotal = finalCartItems.reduce((acc, item) => acc + item.total_price, 0)
                this.cartItems = finalCartItems

            }
        },
        async getSingleProduct(id) {
            try {
                const res = await AgentServices.getSingleProduct(id);
                if (res.data.status) {
                    this.product = res.data.data;
                }
            } catch (error) {
                console.error("Error in GetSingleProduct", error);
            }
        },

        async removeCartItems(sc_ids) {
            try {
                const res = await CommonServices.removeCartItem({ sc_ids });
                if (res.data.status) {
                    this.getCartItems()
                }
            } catch (error) {
                console.error("Error in removeCartItems", error);
            }
        },
    },
    async beforeMount() {
        await this.getAllProducts()
        this.getCartItems()
        // this.getSingleProduct()
    },
}
</script>
<style></style>